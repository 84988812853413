import type { Evento } from '../interfaces/evento';
import type { Entidad } from '../interfaces/entidad';

// import logo from '../assets/images/banner1.jpg';
import logo2 from '../assets/images/logo_short.png';

export const DEFAULT_EVENTO_IMAGE = logo2;

export const getEventoImagePath = (
  evento: Pick<Evento, 'id' | 'hasBanner'>,
  isPublic?: boolean
) => {
  return evento.hasBanner
    ? `${process.env.REACT_APP_IMAGE_URL}/api/uploads/eventos/${evento.id}/logo.jpeg`
    : isPublic
    ? logo2
    : logo2;
};

export const getEntidadImagePath = (entidad: Pick<Entidad, 'id' | 'hasLogo'>) => {
  return entidad.hasLogo
    ? `${process.env.REACT_APP_IMAGE_URL}/api/uploads/entidades/${entidad.id}/logo.jpeg`
    : logo2;
};
