import axios from '../config/axios';

import type { PaginatedResponse } from '../interfaces/baseApiEntity';
import type {
  ComunicacionEmail,
  ComunicacionEmailQueryParams
} from '../interfaces/comunicationEmail';

const PATH = `comunicaciones-emails`;

export const listEmails = async (
  params: ComunicacionEmailQueryParams = {}
): Promise<PaginatedResponse<ComunicacionEmail>> => {
  const response = await axios.get(PATH, { params });
  return response.data;
};

export const listSelfEmails = async (
  params: ComunicacionEmailQueryParams = {}
): Promise<PaginatedResponse<ComunicacionEmail>> => {
  const response = await axios.get(`${PATH}/selfList`, { params });
  return response.data;
};

export const getSelfEmailCount = async (): Promise<number> => {
  const response = await axios.get<{ count: number }>(`${PATH}/selfCount`);
  return response.data.count;
};

export const markAsRead = async (emailId: number): Promise<void> => {
  await axios.put(`${PATH}/selfMarkAsRead`, { ids: [emailId] });
};

export const markAllAsRead = async (): Promise<void> => {
  await axios.put(`${PATH}/selfMarkAsRead`, { all: true });
};

export const readEmail = async (emailId: number): Promise<ComunicacionEmail> => {
  const response = await axios.get(`${PATH}/${emailId}`);
  return response.data;
};

export const deleteEmail = async (emailId: number): Promise<void> => {
  await axios.delete(`${PATH}/${emailId}`);
};

export const ComunicacionesEmailService = {
  listEmails,
  readEmail,
  markAsRead,
  deleteEmail,
  markAllAsRead,
  listSelfEmails,
  getSelfEmailCount
};
