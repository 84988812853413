import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ComunicacionesEmailService } from '../../services/comunicacionesEmail.service';

const initialState: { open: boolean; count: number } = { open: false, count: 0 };

export const fetchNotificationCount = createAsyncThunk<number>(
  'notifications/fetchNotificationCount',
  async (_, { rejectWithValue }) => {
    try {
      return await ComunicacionesEmailService.getSelfEmailCount();
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    openNotifications(state) {
      state.open = true;
    },
    closeNotifications(state) {
      state.open = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationCount.fulfilled, (state, action) => {
      state.count = action.payload;
    });

    builder.addCase(fetchNotificationCount.pending, () => {});

    builder.addCase(fetchNotificationCount.rejected, (state) => {
      state.count = 0;
    });
  }
});

export const notificationsActions = { fetchNotificationCount, ...notificationsSlice.actions };

export default notificationsSlice.reducer;
