import { useCallback, useEffect } from 'react';

import { Avatar, Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/store';
// import { notificationsActions } from '../../../../store/slices/notifications';

// Constants
import { useNavigate } from 'react-router-dom';
import { PRIMARY_COLOR } from '../../../../constants/colors';
import { ROUTES } from '../../../../constants/routes-constants';
import { notificationsActions } from '../../../../store/slices/notifications';

export const NotificationBell = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const count = useAppSelector((state) => state.notifications.count);

  useEffect(() => {
    dispatch(notificationsActions.fetchNotificationCount());
  }, [dispatch]);

  const onClick = useCallback(() => {
    navigate(`${ROUTES.PROFILE_ROUTE}?tab=4`);
  }, [navigate]);

  return (
    <Badge count={count} offset={[-5, 10]}>
      <span onClick={onClick}>
        <Avatar style={{ backgroundColor: PRIMARY_COLOR }} size={30} icon={<BellOutlined />} />
      </span>
    </Badge>
  );
};
