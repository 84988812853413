import axios from '../config/axios';

import type {
  Comunicacion,
  ComunicacionList,
  NewComunicacionForm,
  UpdateComunicacionForm,
  ComunicacionesQueryParams,
  ComunicacionDetails
} from '../interfaces/comunication';
import type { PaginatedResponse } from '../interfaces/baseApiEntity';

const PATH = `comunicaciones`;

/**
 * @description Recupera desde la API las comunicaciones
 */
export const getComunicaciones = async (params: ComunicacionesQueryParams) => {
  return await axios.get<PaginatedResponse<ComunicacionList>>(PATH, { params }).then((response) => {
    return response.data;
  });
};

/**
 * @description Recupera desde la API una comunicación por su id
 */
export const getComunicacionById = async (id: number) => {
  return await axios.get<ComunicacionDetails>(`${PATH}/${id}`).then((response) => {
    return response.data;
  });
};

/**
 * @description Crea una nueva comunicación
 */
export const createComunicacion = async (data: NewComunicacionForm) => {
  return await axios.post<Comunicacion>(PATH, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Actualiza una comunicación existente
 */
export const updateComunicacion = async (id: number, data: UpdateComunicacionForm) => {
  return await axios.patch<ComunicacionDetails>(`${PATH}/${id}`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Elimina una comunicación por su id
 */
export const deleteComunicacion = async (id: number) => {
  return await axios.delete<void>(`${PATH}/${id}`).then(() => {
    return;
  });
};

export const ComunicacionesService = {
  getComunicaciones,
  getComunicacionById,
  createComunicacion,
  updateComunicacion,
  deleteComunicacion
};
