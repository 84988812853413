import axios from '../config/axios';

import type { PaginatedResponse } from '../interfaces/baseApiEntity';
import type {
  Entidad,
  NewEntidadForm,
  EntidadDetallada,
  UpdateEntidadForm,
  EntidadQueryParams
} from '../interfaces/entidad';

const PATH = `entidades`;

export const getEntidad = async (id: number) => {
  return await axios.get<EntidadDetallada>(`${PATH}/${id}`).then((response) => {
    return response.data;
  });
};

export const getMyEntidad = async () => {
  return await axios.get<EntidadDetallada>(`${PATH}/self`).then((response) => {
    return response.data;
  });
};

export const getEntidades = async (params: EntidadQueryParams) => {
  return await axios.get<PaginatedResponse<Entidad>>(PATH, { params }).then((response) => {
    return response.data;
  });
};

/**
 * @description Recupera desde la API los sectores de actividad
 */
export const getEntidadesByIds = async (params: EntidadQueryParams, ids: (string | number)[]) => {
  return await axios
    .post<PaginatedResponse<Entidad>>(`${PATH}/byIds`, { ...params, ids })
    .then((response) => {
      return response.data;
    });
};

export const newEntidad = async (data: NewEntidadForm) => {
  return await axios.post<Entidad>(PATH, data).then((response) => {
    return response.data;
  });
};

export const updateEntidad = async (id: number, data: UpdateEntidadForm) => {
  return await axios.patch<Entidad>(`${PATH}/${id}`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Sube una imagen para asociar a una empresa
 */
export const uploadEntidadImage = async (id: number, image: File) => {
  const formData = new FormData();
  formData.append('logo', image);

  return await axios.patch(`${PATH}/${id}/logo`, formData).then((response) => {
    return response.data;
  });
};

/**
 * @description Sube una imagen a la empresa del usuario
 */
export const uploadSelfEntidadImage = async (id: number, image: File) => {
  const formData = new FormData();
  formData.append('logo', image);

  return await axios.patch(`${PATH}/selfLogo`, formData).then((response) => {
    return response.data;
  });
};

export const EntidadService = {
  getEntidad,
  newEntidad,
  getEntidades,
  updateEntidad,
  getEntidadesByIds,
  uploadEntidadImage,
  uploadSelfEntidadImage
};

export default EntidadService;
