import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';

// Services
import * as ComunicacionService from '../../services/comunicaciones.service';

// Interfaces
import type { ComunicacionDetails } from '../../interfaces/comunication';

// Utils
import dayjs from 'dayjs';

export const fetchComunicacion = createAsyncThunk<ComunicacionDetails, number>(
  'comunicacion/fetchComunicacionStatus',
  // @ts-ignore
  async (id, { rejectWithValue }) => {
    try {
      return ComunicacionService.getComunicacionById(id as number);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

interface ComunicacionState {
  loading: boolean;
  comunicacion: ComunicacionDetails | null;
}

const initialState: ComunicacionState = {
  loading: false,
  comunicacion: null
};

const ComunicacionSlice = createSlice({
  name: 'Comunicacion',
  initialState,
  reducers: {
    setComunicacion: (state, action) => {
      state.comunicacion = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchComunicacion.fulfilled, (state, action) => {
      state.comunicacion = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchComunicacion.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchComunicacion.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const selectComunicacionVariables = createSelector(
  (state: { comunicacion: ComunicacionState }) => state.comunicacion.comunicacion,
  (comunicacion) => {
    if (comunicacion) {
      return {
        ...(comunicacion.variables || {}),
        'evento.nombre': comunicacion.evento?.nombre,
        'evento.lugar': comunicacion.evento?.lugar,
        'evento.dia': comunicacion.evento?.fecha
          ? dayjs(comunicacion.evento?.fecha).format('DD/MM/YYYY')
          : '',
        'evento.hora': comunicacion.evento?.fecha
          ? dayjs(comunicacion.evento?.fecha).format('HH:mm')
          : ''
      };
    }
    return {};
  }
);

export const ComunicacionActions = {
  ...ComunicacionSlice.actions,
  fetchComunicacion
};

export default ComunicacionSlice.reducer;
